<template>
  <div class="content-wrapper">
    <bo-page-header />
    <div class="content pt-0">
      <b-form>
        <b-card no-body>
          <div class="header_card">
            <h5>My Profile</h5>
          </div>
          <b-card-body>
            <b-row>
              <b-col md="7">
                <b-form-group label-for="accName" label-cols-md="3" content-cols-md="8">
                  <template #label>
                    Name <small class="mandatory_txt">*</small>
                  </template>
                  <b-form-input placeholder="e.g. Mega Murdiana" id="accName" />
                </b-form-group>
                <b-form-group label-for="accEmail" label-cols-md="3" content-cols-md="8">
                  <template #label>
                    Email <small class="mandatory_txt">*</small>
                  </template>
                  <b-form-input placeholder="e.g. megamurdiana@mail.com" id="accEmail" />
                </b-form-group>
                <b-form-group label-for="accPass" label-cols-md="3" content-cols-md="6">
                  <template #label>
                    Password <small class="mandatory_txt">*</small>
                  </template>
                  <div>
                    <b-button variant="light" block v-b-toggle="'colPass'">
                      <i class="icon-lock2 mr-2"></i> Change Password
                    </b-button>
                    <b-collapse id="colPass">
                      <b-form-input type="password" class="mt-2" placeholder="New Password" />
                      <b-form-input type="password" class="mt-2" placeholder="Confirm Password" />
                    </b-collapse>
                  </div>
                </b-form-group>
                <b-form-group label-for="accRole" label-cols-md="3" content-cols-md="5">
                  <template #label>
                    Role <small class="mandatory_txt">*</small>
                  </template>
                  <b-form-input placeholder="e.g. Owner" value="Workspace Owner" readonly id="accRole" />
                </b-form-group>
              </b-col>
              <b-col md="5" class="text-center">
                <div class="box_profile"></div>
                <div class="upload_wrapper">
                  <div class="file-upload-box">
                    <label for="upload" class="file-upload__label">
                      <div class="wrap_text">
                        <p>Upload</p>
                      </div>
                    </label>
                    <input id="upload" class="file-upload__input" type="file" name="file-upload">
                  </div>
                </div>
                <div class="wrap_info">
                  <p>Requirement Size (200px x 200px)</p>
                  <p>Format : PNG, JPG, JPEG (Max 2mb)</p>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <div class="header_card">
            <h5>Notification</h5>
          </div>
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Notifications"
                  label-cols-lg="3"
                  content-cols-md="6"
                  label-for="accNotification"
                  label-class="pt-0"
                >
                  <div id="accNotification">
                    <b-form-checkbox>Get Email Notification</b-form-checkbox>
                    <b-form-checkbox>Get Monthly Report</b-form-checkbox>
                  </div>
                </b-form-group>
                <b-form-group
                  label-for="accIntegration"
                  label-cols-lg="3"
                  content-cols-md="6"
                >
                  <template #label>
                    Integrate<small class="mandatory_txt">*</small>
                  </template>
                  <b-button block class="bg_ig"><i class="icon-hash mr-2"></i> Slack</b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <div class="header_card">
            <h5>Users</h5>
          </div>
          <div class="table-responsive">
            <table class="table table-stripped   text-nowrap">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Mega Muridana</td>
                  <td><span class="font-weight-bold mb-0 text-indigo">Workspace Owner</span></td>
                  <td><span class="font-weight-bold mb-0"><a href="#" class="btn bg-transparent border-success-400 text-success-400 rounded-round border-2 btn-icon mr-1">
                    <i class="icon-pencil7"></i>
                    </a></span>
                  </td>
                </tr>
                <tr>
                  <td>Mega Muridana</td>
                  <td><span class="font-weight-bold mb-0 text-indigo">Admin</span></td>
                  <td><span class="font-weight-bold mb-0"><a href="#" class="btn bg-transparent border-success-400 text-success-400 rounded-round border-2 btn-icon mr-1">
                    <i class="icon-pencil7"></i>
                    </a></span>
                  </td>
                </tr>
                <tr>
                  <td>Tita Aprilianti</td>
                  <td><span class="font-weight-bold mb-0 text-indigo">Admin</span></td>
                  <td><span class="font-weight-bold mb-0"><a href="#" class="btn bg-transparent border-success-400 text-success-400 rounded-round border-2 btn-icon mr-1">
                    <i class="icon-pencil7"></i>
                    </a></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <template #footer>
            <button type="button" class="btn bg-indigo-400"><i class="icon-check2  mr-2"></i> Save</button>
            <button type="button" class="btn  bg-slate-400"> Reset</button>
          </template>
        </b-card>
      </b-form>
    </div>
    <bo-footer />
  </div>
</template>
<script>
import GlobalVue from '../libs/Global.vue'

export default {
  extends: GlobalVue,
}
</script>
